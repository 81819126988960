import React from "react";
import CartItem from "./CartItem";
import CartContext from "./CartContext";
import { useContext, useState } from "react";

const CartItems = () => {
  const { items } = useContext(CartContext);

  return (
    <div className="cartItemsDisplay">
      {items.map((cartItem, index) => (
        <CartItem
          key={index}
          imgsrc={cartItem.imgsrc}
          title={cartItem.title}
          size={cartItem.size}
          price={cartItem.price}
          qty={cartItem.qty}
          id={cartItem.id}
        />
      ))}
    </div>
  );
};

export default CartItems;
