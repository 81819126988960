import React, { useEffect, useState } from "react";
import ItemCard from "./ItemCard";
/* import CartItems from "./CartItems"; */
import { faPepperHot } from "@fortawesome/free-solid-svg-icons"; // <-- import styles to be used
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* import CartContext from "./CartContext";
import { useContext } from "react"; */

const Products = (props) => {
  /* const { items } = useContext(CartContext); */

  const itemcards = [
    {
      imgsrc: "HELIOS_ORANGE.png",
      title: "HELIOS",
      desc: "Habeñero & Carrot",
      price: "12.99",
      longdesc:
        "This is the go-to sauce, the one that's great on everything. Sharing it's name with Ancient Greek word for our Sun, it has just enough heat from the Habeñeros, and has a decent funk from the fermented carrots. Super versitile, from eggs to burritos, chili to hot wings, splash a few drops into a Bloody Mary if you're daring!",
      ingrd:
        "Habeñero, Carrots, White Onion, Garlic, Water, Salt, Spices, Xantham Gum to help prevent seperation.",
      heat: (
        <>
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="unheat" />
          <FontAwesomeIcon icon={faPepperHot} className="unheat" />
        </>
      ),
      size: "8oz Squeeze",
      qty: 1,
      id: "HELIOS8",
    },

    {
      imgsrc: "SIRIUS_RED.png",
      title: "SIRIUS",
      desc: "Cayenne & Ghost",
      price: "12.99",
      longdesc:
        "The goal was to take all the great flavor from those Lousiana-Style sauces and increase the heat level without taking anything away from the flavor of the Cayenne, Garlic, and Vinegar",
      ingrd:
        "Cayenne Peppers, Ghost Peppers, Garlic, Distilled White Vinegar, Water, Salt, Spices, Xantham Gum to help prevent seperation.",
      heat: (
        <>
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="unheat" />
        </>
      ),
      size: "8oz Squeeze",
      qty: 1,
      id: "SIRIUS8",
    },

    {
      imgsrc: "CAPELLA_YELLOW.png",
      title: "CAPELLA",
      desc: "SCOTCH BONNET & GINGER",
      price: "12.99",
      longdesc:
        "Our Jamacian Styled Sauce, slightly fruity, with hints of curry and thyme. Capella is a yellow giant and the brightest star in the northern constellation of Auriga.  It's name means little goat in Latin",
      ingrd:
        "Yellow Scotch Bonnet Peppers, Yellow and White Carrots, Garlic, Distilled White Vinegar, Water, Salt, Spices, Xantham Gum to help prevent seperation.",
      heat: (
        <>
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="unheat" />
          <FontAwesomeIcon icon={faPepperHot} className="unheat" />
          <FontAwesomeIcon icon={faPepperHot} className="unheat" />
        </>
      ),
      size: "8oz Squeeze",
      qty: 1,
      id: "CAPELLA8",
    },

    {
      imgsrc: "ANTARES_GREEN.png",
      title: "ANTARES",
      desc: "THAI CHILI & LEMONGRASS",
      price: "12.99",
      longdesc:
        "This sauce is fragrent, lemongrass and basil are noticible. Works great as a counter to sweet asian dishes. The sometimes green hue of Antares is the result of the mixing of light from two stars.",
      ingrd:
        "Green Thai Chillis, Lemongrass, Basil, Ginger, Garlic, Rice Wine Vinegar, Water, Salt, Spices, Xantham Gum to help prevent seperation.",
      heat: (
        <>
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="heat" />
          <FontAwesomeIcon icon={faPepperHot} className="unheat" />
          <FontAwesomeIcon icon={faPepperHot} className="unheat" />
        </>
      ),
      size: "8oz Squeeze",
      qty: 1,
      id: "ANTARES8",
    },
  ];

  const [sauces, setSauces] = useState([]);

  // This method fetches the records from the database.
  useEffect(() => {
    async function getSauces() {
      const response = await fetch(`http://localhost:5000/sauce/`);

      if (!response.ok) {
        const message = `This shit broke: ${response.statusText}`;
        window.alert(message);
        return;
      }

      //something broken with the json coming from mongo

      const sauces = await response.json();
      setSauces(sauces);

      console.log("fuckity fuck");
    }

    getSauces();

    return;
  }, [sauces.length]);

  console.log(sauces.length);
  console.log(sauces);

  return (
    <section className="productList">
      {itemcards.map((itemcard, index) => (
        <ItemCard
          key={index}
          imgsrc={itemcard.imgsrc}
          title={itemcard.title}
          desc={itemcard.desc}
          price={itemcard.price}
          longdesc={itemcard.longdesc}
          ingrd={itemcard.ingrd}
          heat={itemcard.heat}
          size={itemcard.size}
          qty={itemcard.qty}
          id={itemcard.id}
        />
      ))}
    </section>
  );
};

export default Products;
