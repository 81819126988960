import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <div className="homeBody">
        <img src="SAUCE_COMPOSITE.png" className="HeroImg" alt="" />
        <div className="homeTitle">Small-Batch Fermented Hot Sauces.</div>
        <button className="addToCart">
          <Link className="BuyNow" to="/Products">
            BUY NOW
          </Link>
        </button>
        <p>
          After some small batch hot sauces falling flat, we decided to give it
          a go ourselves, and have been making 32 and 64 ounce batches ever
          since.{" "}
          <Link className="ReadMore" to="/About">
            Read More!
          </Link>
        </p>
      </div>
    </>
  );
}

export default Home;
