import React, { useState } from "react";
/* import ReactDOM from "react-dom/client"; */
import { Outlet, Link } from "react-router-dom";
import CartItems from "./CartItems";
import CartContext from "./CartContext";
import { useContext } from "react";

const Navigation = () => {
  /* BURGER MENU STUFF */
  const [burgerToggle, setBurgerToggle] = useState(false);

  const burgerToggler = () => {
    setBurgerToggle((prev) => !prev);
  };
  /* END BURGER MENU STUFF */

  /* DARK MODE STUFF */
  const [darkmode, setdarkmode] = useState(false);

  const darkmodetoggle = () => {
    setdarkmode((prev) => !prev);
    darkmode ? setLightmode() : setDarkmode();
  };

  function setLightmode() {
    let logo = document.getElementsByClassName("logo")[0];
    logo.setAttribute("src", "Ateros_Logo_Altered_Black.svg");
    document.documentElement.style.setProperty(
      "--main-text-color",
      "rgb(15, 15, 15)"
    );
    document.documentElement.style.setProperty(
      "--main-bg-color",
      "rgb(255, 255, 255)"
    );
    document.documentElement.style.setProperty(
      "--main-text-contast-color",
      "rgb(245, 245, 245)"
    );
    document.documentElement.style.setProperty(
      "--alt-text-color-1",
      "rgb(69, 69, 69)"
    );
    document.documentElement.style.setProperty(
      "--cart-bg-color",
      "rgb(255, 255, 255)"
    );
    document.documentElement.style.setProperty(
      "--highlight-bg-color",
      "rgb(231, 231, 231)"
    );
    document.documentElement.style.setProperty(
      "--unheat-color",
      "rgb(212, 212, 212)"
    );
  }

  function setDarkmode() {
    let logo = document.getElementsByClassName("logo")[0];
    logo.setAttribute("src", "Ateros_Logo_Altered_White.svg");
    document.documentElement.style.setProperty(
      "--main-text-color",
      "rgb(245, 245, 245)"
    );
    document.documentElement.style.setProperty(
      "--main-bg-color",
      "rgb(15, 15, 15)"
    );
    document.documentElement.style.setProperty(
      "--main-text-contast-color",
      "rgb(245, 245, 245)"
    );
    document.documentElement.style.setProperty(
      "--alt-text-color-1",
      "rgb(69, 69, 69)"
    );
    document.documentElement.style.setProperty(
      "--cart-bg-color",
      "rgb(15, 15, 15)"
    );
    document.documentElement.style.setProperty(
      "--highlight-bg-color",
      "rgb(35, 35, 35)"
    );
    document.documentElement.style.setProperty(
      "--unheat-color",
      "rgb(69, 69, 69)"
    );
  }
  /* END DARKMODE STUFF */

  /* CART WINDOW STUFF */
  const [cartOpen, setCartOpen] = useState(false);

  const cartToggle = () => {
    setCartOpen((prev) => !prev);
    cartOpen
      ? (document.body.style.overflow = "scroll")
      : (document.body.style.overflow = "hidden");
  };
  /* END CART WINDOW STUFF */

  const { itemCountTotal } = useContext(CartContext);
  const { subtotal } = useContext(CartContext);

  return (
    <>
      {/* HEADER IMG */}
      <img className="logo" src="Ateros_Logo_Altered_Black.svg" alt="" />

      {/* DESKTOP NAV */}
      <div className="headerWrap">
        <nav className="fixedNav">
          <ul className="navlinks">
            <li>
              <Link to="/Home" className="fixednav">
                Home
              </Link>
            </li>
            <li>
              <Link to="/About" className="fixednav">
                About
              </Link>
            </li>
            <li>
              <Link to="/Products" className="fixednav">
                Products
              </Link>
            </li>
            <li>
              <Link to="/Contact" className="fixednav">
                Contact
              </Link>
            </li>
            <li>
              <div className="iconholder">
                <i
                  id="desktopbulb"
                  className={
                    darkmode ? "bi bi-lightbulb" : "bi bi-lightbulb-off"
                  }
                  onClick={darkmodetoggle}
                ></i>

                <div className="cartIconAssyDesk">
                  <i
                    id="desktopcart"
                    className="bi bi-cart3"
                    onClick={cartToggle}
                  />
                  <div
                    style={{ display: itemCountTotal > 0 ? "flex" : "none" }}
                    className="cartCountDesk"
                    onClick={cartToggle}
                  >
                    {itemCountTotal}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>

      {/* MOBILE NAV */}
      <div className="hamburger-menu">
        <input
          className="menu__toggle"
          id="menu__toggle"
          type="checkbox"
          checked={burgerToggle}
          onChange={(e) => setBurgerToggle(e.target.checked)}
        />

        <div id="navbar">
          <div className="box" onClick={burgerToggler}>
            <div className={burgerToggle ? "btn active" : "btn not-active"}>
              <span className="burgSpan"></span>
              <span className="burgSpan"></span>
              <span className="burgSpan"></span>
            </div>
          </div>
          <div className="iconHolderMobile">
            <i
              id="mobilebulb"
              className={darkmode ? "bi bi-lightbulb" : "bi bi-lightbulb-off"}
              onClick={darkmodetoggle}
            ></i>
            <div className="cartIconAssyMobile">
              <i id="mobilecart" className="bi bi-cart3" onClick={cartToggle} />
              <div
                style={{ display: itemCountTotal > 0 ? "flex" : "none" }}
                className="cartCountMobile"
                onClick={cartToggle}
              >
                {itemCountTotal}
              </div>
            </div>
          </div>
        </div>
        <ul className="menu__box">
          <li>
            <Link className="menu__item1" to="/Home" onClick={burgerToggler}>
              Home
            </Link>
          </li>
          <li>
            <Link className="menu__item2" to="/About" onClick={burgerToggler}>
              About
            </Link>
          </li>
          <li>
            <Link
              className="menu__item3"
              to="/Products"
              onClick={burgerToggler}
            >
              Products
            </Link>
          </li>
          <li>
            <Link className="menu__item4" to="/Contact" onClick={burgerToggler}>
              Contact
            </Link>
          </li>
        </ul>
      </div>

      {/* MODAL SHOPPING CART */}
      <div id="shoppingCart" className={cartOpen ? "cartOpen" : "cartClosed"}>
        <div id="cartContents">
          <i id="cartCloseMobile" className="bi bi-x" onClick={cartToggle}></i>
          <CartItems />
          <div
            style={{ display: itemCountTotal > 0 ? "inline-flex" : "none" }}
            className="subtotalLine"
          >
            <p>Subtotal:</p>
            <p>${subtotal}</p>
          </div>
          <button
            style={{ display: itemCountTotal > 0 ? "block" : "none" }}
            className="checkout"
          >
            CHECKOUT
          </button>
          <div
            style={{ display: itemCountTotal < 1 ? "inline-flex" : "none" }}
            className="emptyMessage"
          >
            <p>Cart Is Empty</p>
          </div>
        </div>
      </div>

      {/* END MODAL SHOPPING CART */}

      <Outlet />
    </>
  );
};

export default Navigation;
