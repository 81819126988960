import React from "react";

function About() {
  return (
    <>
      <div className="aboutBody">
        <h1>About Us</h1>
        <p>
          We started after buying some hot sauce from a family friend's small
          business. It wasn't a bad sauce by any means, but it wasn't
          exceptional either. Which led us to the thought that dooms all
          obsessive hobbyists "I bet I could do that."
          <br />
          <br />
          We did what most people who Google "How to make hot sauce" do... We
          made a Pineapple Habeñero sauce with grocery store ingredients. I
          guess most people might not go the fermentation route the first time,
          but we always dive head first into these things. The first batch was
          just ok, basically a kinda-but-not-really-spicy, pretty-sweet sauce.
          Though, that one successful sauce was enough to start us down the real
          rabbit hole: Hot Sauce Forums, Reddit, Youtube, Books on Fermentation,
          bigger Mason jars, different types of fermentation airlocks, glass
          woozy bottles, growing chilies, sourcing pepper growers, etc...
          <br />
          <br />
          The next several recipes went well, a Habeñero & Carrot sauce that
          eventually became the Helios sauce, a Thai Chili sauce that became
          Antares, and a failed Blueberry Habeñero that taught us that Aromatics
          will overpower fruity berries very easily.
          <br />
          <br />
          It wasn't until a Day-Job work project involving HTML gave us the idea
          to practice some code writing on a fictitious Hot Sauce company
          website, that the idea of actually producing for sale to the public
          became a possibility.
        </p>
      </div>
    </>
  );
}

export default About;
