import { createContext, useState, useEffect } from "react";

const CartContext = createContext();

export function CartProvider({ children }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    if (cartData) {
      setItems(cartData);
    }
  }, []);

  useEffect(() => {
    // storing cart items
    localStorage.setItem("cartItems", JSON.stringify(items));
  }, [items]);

  const itemCountTotal = items.reduce(
    (quantity, item) => item.qty + quantity,
    0
  );

  const subtotal = items
    .reduce((total, cartItem) => total + cartItem.qty * cartItem.price, 0)
    .toFixed(2);

  const addToCart = (imgsrc, title, size, price, qty, id) => {
    setItems((prevState) => {
      if (prevState.find((item) => item.id === id) == null) {
        return [...prevState, { imgsrc, title, size, price, qty, id }];
      } else {
        return prevState;
      }
    });
  };

  const removeFromCart = (idToDelete) => {
    setItems((prevState) =>
      prevState.filter((items) => items.id !== idToDelete)
    );
  };

  function increaseQty(id) {
    setItems((prevState) => {
      if (prevState.find((item) => item.id === id) == null) {
        /* return [...prevState, { id, qty: 1 }]; */
        return prevState;
      } else {
        return prevState.map((item) => {
          if (item.id === id) {
            if (item.qty < 10) {
              return { ...item, qty: item.qty + 1 };
            } else {
              return item;
            }
          } else {
            return item;
          }
        });
      }
    });
  }

  function decreaseQty(id) {
    setItems((prevState) => {
      if (prevState.find((item) => item.id === id) == null) {
        /* return [...prevState, { id, qty: 1 }]; */
        return prevState;
      } else {
        return prevState.map((item) => {
          if (item.id === id) {
            if (item.qty > 1) {
              return { ...item, qty: item.qty - 1 };
            } else {
              return item;
            }
          } else {
            return item;
          }
        });
      }
    });
  }

  return (
    <CartContext.Provider
      value={{
        items,
        addToCart,
        removeFromCart,
        increaseQty,
        decreaseQty,
        itemCountTotal,
        subtotal,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default CartContext;
