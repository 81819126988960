import React, { useContext } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import CartContext from "./CartContext";

const ItemCard = ({
  _id,
  imgsrc,
  title,
  heat,
  desc,
  price,
  longdesc,
  ingrd,
  qty,
  size,
  id,
}) => {
  /* const [toggle, setToggle] = useState(false); */

  const toggler = (e) => {
    let siblingElement = e.currentTarget.nextElementSibling;

    if (siblingElement.style.display === "none") {
      siblingElement.style.display = "block";
    } else {
      siblingElement.style.display = "none";
    }
  };

  /* const { items } = useContext(CartContext); */
  const { addToCart } = useContext(CartContext);

  return (
    <div className="itemCard">
      <div className="splideWrapper">
        <Splide
          options={{
            width: 500,
            arrows: false,
          }}
        >
          <SplideSlide>
            <img className="itemImage" src={imgsrc} alt="" />
          </SplideSlide>
          <SplideSlide>
            <img className="itemImage" src="NutLabel.png" alt="" />
          </SplideSlide>
        </Splide>
      </div>
      <div className="itemBlurb">
        <h2 className="itemTitle">{title}</h2>
        <div className="itemHeat">{heat}</div>
      </div>
      <div className="itemDetails">
        <p className="itemDesc">{desc}</p>
        <p className="itemSize">{size}</p>
        <p className="itemPrice">{price}</p>
      </div>
      <div className="accordianWrapper">
        <button className="accordion" onClick={toggler}>
          <p>About</p>
        </button>
        <div className="panel" style={{ display: "none" }}>
          <p>{longdesc}</p>
        </div>
        <button className="accordion" onClick={toggler}>
          <p>Ingredients</p>
        </button>
        <div className="panel" style={{ display: "none" }}>
          <p>{ingrd}</p>
        </div>
      </div>
      <button
        className="addToCart"
        onClick={() => addToCart(imgsrc, title, size, price, qty, id)}
      >
        Add To Cart
      </button>
    </div>
  );
};
export default ItemCard;
