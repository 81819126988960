import React, { useState } from "react";
import { send } from "emailjs-com";
import contactimg from "../assets/saucecontact.jpg";

function Contact() {
  const [toSend, setToSend] = useState({
    from_name: "",
    message: "",
    reply_to: "",
  });

  const [buttonMessage, toggleMessage] = useState("SUBMIT");

  const handleSubmit = (e) => {
    if (buttonMessage !== "MESSAGE SENT") {
      e.preventDefault();
      send("service_qnqi926", "template_bus8rjg", toSend, "9M94sGlhQhhEiilew")
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          toggleMessage("MESSAGE SENT");
        })
        .catch((err) => {
          console.log("FAILED...", err);
          toggleMessage("FAILED");
        });
    } else if (buttonMessage === "MESSAGE SENT") {
      e.preventDefault();
      console.log("Multiple successful message blocked");
      alert("Message already Sent");
    } else {
      e.preventDefault();
      console.log("Multiple message blocked");
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className="contactwrapper">
      <div className="contactimage">
        <img src={contactimg} alt="" />
      </div>
      <div className="contactBody">
        <h1 className="contactBlurb">
          Want to chat about Hot Sauce? Peppers? Fermentation? Recipes?
        </h1>
        <div className="container">
          <form className="contactForm" onSubmit={handleSubmit}>
            <label className="formLabel">Name</label>
            <input
              style={{
                fontFamily: "Source Sans 3, bootstrap-icons",
              }}
              type="text"
              id="name"
              name="from_name"
              required
              placeholder=" "
              value={toSend.from_name}
              onChange={handleChange}
            />

            <label className="formLabel">E-Mail</label>
            <input
              style={{
                fontFamily: "Kantumruy Pro, bootstrap-icons",
              }}
              type="text"
              id="email"
              name="reply_to"
              required
              placeholder=" "
              value={toSend.reply_to}
              onChange={handleChange}
            />

            <label className="formLabel">Message</label>
            <textarea
              style={{
                fontFamily: "Kantumruy Pro, bootstrap-icons",
              }}
              id="message"
              name="message"
              required
              placeholder=" "
              value={toSend.message}
              onChange={handleChange}
            ></textarea>

            <button type="submit" className="submit">
              {buttonMessage}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
