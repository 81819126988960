import React, { useContext, useState } from "react";
import CartContext from "./CartContext";

const CartItem = ({ imgsrc, title, size, price, qty, id }) => {
  const { removeFromCart } = useContext(CartContext);
  const { increaseQty } = useContext(CartContext);
  const { decreaseQty } = useContext(CartContext);

  return (
    <div className="cart_item">
      <div id="prod_image" className="cart_item_detail">
        <img className="sauce" src={imgsrc} alt="" />
      </div>

      <div id="prod_details" className="cart_item_detail">
        <div id="sub_details">
          <p className="item_title">{title}</p>
        </div>

        <div id="sub_details">
          <p className="item_size">{size}</p>
        </div>

        <div id="sub_details">
          <p className="item_price">${price}</p>
        </div>

        <div id="sub_details">
          <div className="inputAssy">
            <i className="bi bi-dash" onClick={() => decreaseQty(id)}></i>
            <span className="quantityBox">{qty}</span>
            <i className="bi bi-plus" onClick={() => increaseQty(id)}></i>
          </div>
        </div>
      </div>

      <div id="cart_remove" className="cart_item_detail">
        <div className="trashwrapper">
          <i
            id="trash_item"
            className="bi bi-trash3"
            onClick={() => removeFromCart(id)}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
